<script setup>
import { useEmbed } from '~/composables/use-embed';
import { onBeforeRouteUpdate } from '#imports';
import { network } from '~/constants/embedded-social-scripts';

defineProps({
  content: {
    type: String,
    required: true,
  },
  caption: {
    type: String,
    default: '',
  },
});

const { loadEmbed } = useEmbed({
  // Social Media should be named like in window object
  socialMediaName: 'instgrm',
  script: network.instagram,
});

onBeforeRouteUpdate(() => {
  loadEmbed();
});
</script>

<template>
  <div :key="caption" class="embed embed--instagram" :title="caption">
    <div class="embed__container">
      <div class="embed__content" v-html="content" />
      <div class="embed__caption">{{ caption }}</div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
@import './styles.css';
</style>
