<script setup>
import { ref } from '#imports';
import { useEventTracking } from '~/utils/use-event-tracking';

defineProps({
  author: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  content: {
    type: Array,
    required: true,
  },
  withDonationButton: {
    type: Boolean,
    // eslint-disable-next-line vue/no-boolean-default
    default: true,
  },
});

const showForm = ref(false);

const handleToggleDonationForm = () => {
  showForm.value = true;
  useEventTracking('Payment Form', 'Opened in Author opinion block');
};
</script>

<template>
  <div class="author-opinion">
    <img
      :class="{ 'author-opinion__background-image--full': showForm }"
      alt="background image"
      class="author-opinion__background-image"
      src="~/assets/images/donation-background.svg"
    />
    <div class="author-opinion__title">{{ title }}</div>
    <div class="author-opinion__text">
      <PostContent :content="content" is-author-opinion />
    </div>
    <div class="author-opinion__author">
      <BaseAvatar :image="author.image" size="big" />
      <div class="author-opinion__author-name">
        {{ author.name }}
      </div>
    </div>
    <div v-if="withDonationButton" class="author-opinion__footer">
      <button class="author-opinion__button" @click="handleToggleDonationForm">
        Поддержать
      </button>
      <div class="author-opinion__footer-text">
        Оформите разовое или ежемесячное пожертвование.
      </div>
    </div>
    <Transition mode="out-in" name="scale-in">
      <div v-if="showForm" class="author-opinion__form">
        <DonationBlockPayment />
      </div>
    </Transition>
  </div>
</template>

<style lang="postcss" scoped>
.author-opinion {
  position: relative;
  z-index: 1;
  max-width: var(--content-max-width);
  margin: 0 auto;
  padding: var(--article-content-padding);
  padding-top: 100px;
  padding-bottom: 70px;
  color: var(--white-color);

  @media (--tablet-vp) {
    padding: 100px 140px 70px;
  }

  &__background-image {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-65%) scale(130%);
    width: auto;
    height: 100%;
    object-fit: cover;
    transition: 0.15s width, 0.15s transform;
    user-select: none;
    pointer-events: none;

    &--full {
      width: auto;
      transform: translateX(-50%) scale(120%);
    }
  }

  &__title {
    position: relative;
    margin-bottom: 30px;
    font-size: var(--h6-font-size);
    line-height: var(--h6-line-height);
    font-weight: 700;

    &::before {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background-color: var(--page-background-color);
      opacity: 0.4;
    }
  }

  &__text {
    position: relative;
    max-width: 600px;
    margin-bottom: 24px;

    &::after,
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      width: 90px;
      height: 87px;
      background-image: url('~/assets/images/quote-transparent.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }

    &::before {
      top: 0;
      bottom: auto;
      left: -100px;
      right: auto;
    }

    &::after {
      top: auto;
      bottom: 0;
      left: auto;
      right: -90px;
      transform: rotate(180deg);
    }
  }

  &__author {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
  }

  &__author-name {
    width: min-content;
    min-width: 151px;
    font-size: var(--h6-font-size);
    line-height: var(--h6-line-height);
    font-weight: 700;
  }

  &__footer {
    max-width: 784px;
    display: flex;
    gap: 16px;
  }

  &__button {
    position: relative;
    width: 226px;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 54px;
    padding: 5px 31px;
    border-radius: 12px;
    text-align: center;
    background-color: #74ffab;
    color: var(--black-color);
    cursor: pointer;
    transition: var(--default-transition) background-color;

    &:hover {
      background-color: #23ff7b;
    }

    &:active {
      background-color: #1ee46e;
    }
  }

  &__footer-text {
    max-width: 190px;
    font-size: 16px;
    line-height: 20px;
    color: var(--page-background-color);
    opacity: 0.8;
  }

  &__form {
    margin-top: 32px;
    margin-bottom: -100px;
  }

  @media (max-width: 1349px) {
    &__background-image {
      transform: translateX(-55%) scale(110%);
    }
  }

  @media (max-width: 1439px) {
    &__form {
      position: relative;
      left: -20%;
    }
  }

  @media (--small-vp) {
    padding: 50px 12px;

    &__title {
      font-size: 16px;
      line-height: 22px;
    }

    &__text {
      margin-bottom: 8px;
      padding: 0 12px;

      &::after,
      &::before {
        width: 40px;
        height: 40px;
        background-size: 40px 40px;
      }

      &::before {
        top: -10px;
        bottom: auto;
        left: -12px;
        right: auto;
      }

      &::after {
        top: auto;
        bottom: -10px;
        left: auto;
        right: -12px;
      }
    }

    &__author {
      margin-bottom: 16px;
    }

    &__author-name {
      font-size: 14px;
      line-height: 18px;
    }

    &__background-image {
      transform-origin: center;
      transform: translateX(-50%) scale(120%);

      &--full {
        transform: translateX(-50%) scale(110%);
      }
    }

    &__form {
      left: 0;
      margin-bottom: 0;
    }

    &__footer {
      flex-direction: column;
      gap: 8px;
    }

    &__button {
      width: auto;
      margin-bottom: 0;
    }

    &__footer-text {
      max-width: none;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
    }
  }

  @media (--x-small-vp) {
    padding: 40px 12px;
  }
}
</style>
