import Typograf from 'typograf';

const typograf = new Typograf({ locale: ['ru', 'en-US'] });

typograf.disableRule('common/space/trimLeft');
typograf.disableRule('common/space/trimRight');
typograf.disableRule('common/number/times');
typograf.disableRule('common/space/afterColon');
typograf.disableRule('common/punctuation/quote');
typograf.setSetting('common/nbsp/afterShortWord', 'lengthShortWord', 3);

export function processTextContent(content) {
  return content.map((block) => {
    // eslint-disable-next-line no-underscore-dangle
    if (block._type !== 'block') return block;

    const precessedChildren = block.children.map((child) => {
      const precessedText = typograf.execute(child.text);
      return { ...child, text: precessedText };
    });

    return {
      ...block,
      children: precessedChildren,
    };
  });
}
