<template>
  <span class="hr">
    <span class="hr__inner">
      <slot />
    </span>
  </span>
</template>

<style scoped lang="postcss">
.hr {
  display: block;
  margin: 40px auto;

  &__inner {
    display: block;
    height: 2px;
    background: linear-gradient(
      90deg,
      transparent 0%,
      rgba(var(--black-color--rgb), 20%) 20%,
      rgba(var(--black-color--rgb), 20%) 80%,
      transparent 100%
    );
  }

  @media (--small-vp) {
    margin: 32px auto;
  }
}
</style>
