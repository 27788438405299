<template>
  <span class="highlight-block">
    <mark><slot /></mark>
  </span>
</template>

<style scoped lang="postcss">
.highlight-block {
  display: block;
  margin: 32px 0;
  font-size: var(--h3-font-size);
  line-height: 42px;
  font-weight: 700;
  white-space: pre-line;

  mark {
    background-color: var(--accent-second-color);
    box-shadow: 8px 0 0 var(--accent-second-color),
      -8px 0 0 var(--accent-second-color);
    box-decoration-break: clone;
  }

  @media (--small-vp) {
    margin: 28px 0;
    font-size: 18px;
    line-height: 22px;
  }
}
</style>
