<script setup>
import { computed } from '#imports';

const props = defineProps({
  image: {
    type: Object,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    default: '',
  },
  content: {
    type: Object,
    default: null,
  },
  frame: {
    type: String,
    default: '',
  },
});

const assetId = computed(() => props.image.asset._ref);
const frameClass = computed(() =>
  props.frame ? `content-speaker__avatar-image--${props.frame}` : ''
);
</script>

<template>
  <div class="content-speaker">
    <div class="content-speaker__shadow"></div>
    <div class="content-speaker__header">
      <div class="content-speaker__avatar">
        <SanityImage
          :class="[frameClass, 'content-speaker__avatar-image']"
          :asset-id="assetId"
          :alt="name"
          :width="160"
          auto="format"
        />
        <img
          v-if="!frameClass"
          class="content-speaker__avatar-mask"
          src="~/assets/images/speaker-avatar-mask.svg"
          alt="avatar-mask"
        />
      </div>
      <div class="content-speaker__text">
        <div v-if="description" class="content-speaker__description">
          {{ description }}
        </div>
        <div class="content-speaker__name">
          {{ name }}
        </div>
      </div>
    </div>
    <div v-if="content" class="content-speaker__content">
      <PostContent :content="content" is-article />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.content-speaker {
  --avatar-size: 128px;

  position: relative;
  z-index: 1;
  margin-top: 20px;
  padding-top: 60px;

  &__shadow {
    position: absolute;
    z-index: -1;
    top: 40px;
    left: 50%;
    width: 100%;
    height: var(--avatar-size);
    transform: translateX(-50%);
    background-color: #e2d3e4;

    &::after,
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
    }

    &::before {
      top: -60px;
      height: 60px;
      background: linear-gradient(
        0deg,
        rgb(226, 211, 228) 0%,
        rgba(255, 255, 255, 0%)
      );
    }

    &::after {
      top: auto;
      bottom: -80px;
      height: 80px;
      background: linear-gradient(
        180deg,
        rgb(226, 211, 228) 20%,
        rgba(255, 255, 255, 0%)
      );
      @media (--small-vp) {
        bottom: -40px;
        height: 40px;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 16px;
    max-width: var(--content-max-width);
    margin: 0 auto 38px;
    padding: var(--article-content-padding);

    @media (--tablet-vp) {
      padding: 0 60px;
    }
  }

  &__avatar {
    position: relative;
    z-index: 1;
  }

  &__avatar-image {
    display: block;
    width: var(--avatar-size);
    height: var(--avatar-size);
    object-fit: cover;
    object-position: center;
  }

  &__avatar-image--isRounded {
    border-radius: 50%;
    box-shadow: 4px 2px 0 0 #fcfe4a;
    width: 118px;
    height: 118px;
  }

  &__avatar-mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: var(--avatar-size);
    height: var(--avatar-size);
    transform: translateX(-50%);
  }

  &__description {
    font-size: 18px;
    line-height: 36px;
    color: var(--accent-color);
    margin-bottom: -4px;
  }

  &__name {
    font-family: var(--title-font-family);
    font-size: var(--h3-font-size);
    line-height: var(--h3-line-height);
    font-weight: 700;
  }

  @media (--small-vp) {
    --avatar-size: 100px;

    margin-top: 40px;
    padding-top: 40px;

    &__avatar {
      width: var(--avatar-size);
      min-width: var(--avatar-size);
      height: var(--avatar-size);
    }

    &__avatar-image--isRounded {
      border-radius: 50%;
      box-shadow: 4px 2px 0 0 #fcfe4a;
      width: 96px;
      min-width: 96px;
      height: 96px;
    }

    &__header {
      margin: 0 auto 28px;
      padding: 0 12px;
    }

    &__description {
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 0;
    }

    &__name {
      font-size: 24px;
      line-height: 24px;
    }
  }
}
</style>
