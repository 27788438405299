import { ref, onMounted, onActivated } from 'vue';
import { appendEmbeddedSocialScript } from '~/utils/append-embedded-social-script';

export function useEmbed(props) {
  const isLoaded = ref(false);

  const loadEmbed = () => {
    if (!isLoaded.value) {
      appendEmbeddedSocialScript(props.script);
      if (window[props.socialMediaName]) {
        window[props.socialMediaName].Embeds.process();
      }

      isLoaded.value = true;
    }
  };

  onMounted(loadEmbed);
  onActivated(loadEmbed);

  return {
    loadEmbed,
  };
}
