<script setup>
import { computed } from '#imports';

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
});

const size = computed(() => props.post.size);
const titleColorCustom = computed(() => {
  const title = props.post.titleColor;
  return title ? { color: title.hex } : '';
});

const backgroundColor = computed(() => {
  const bg = props.post.backgroundColor;
  return bg ? { backgroundColor: bg.hex || '', opacity: 1 } : '';
});
const classes = computed(() => ({
  'post-card--big': size.value === 'big',
  'post-card--custom-background': Boolean(props.post.backgroundColor),
}));
</script>

<template>
  <div
    class="post-card post-card--news"
    :class="classes"
    :style="backgroundColor"
  >
    <p class="post-card__title" :style="titleColorCustom">
      {{ post.title }}
    </p>
    <div class="post-card__date">
      <PostCardDate :date="post.publishedAt" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
@import './styles.css';
</style>
