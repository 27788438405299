<script setup>
import { useEmbed } from '~/composables/use-embed';
import { onBeforeRouteUpdate } from '#imports';
import { network } from '~/constants/embedded-social-scripts';

defineProps({
  content: {
    type: String,
    required: true,
  },
  caption: {
    type: String,
    default: '',
  },
});

const { loadEmbed } = useEmbed({
  socialMediaName: 'tiktok',
  script: network.tiktok,
});

onBeforeRouteUpdate(() => {
  loadEmbed();
});
</script>

<template>
  <div class="embed embed--tiktok" :title="caption">
    <div class="embed__content" v-html="content" />
    <div class="embed__caption">{{ caption }}</div>
  </div>
</template>

<style lang="postcss" scoped>
@import './styles.css';
</style>
