export function hexToRgb(hex) {
  const exec = /^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i.exec(hex);

  if (!exec) return '';

  const rgb = {
    r: Number.parseInt(exec[1], 16),
    g: Number.parseInt(exec[2], 16),
    b: Number.parseInt(exec[3], 16),
  };

  return `${rgb.r}, ${rgb.g}, ${rgb.b}`;
}
