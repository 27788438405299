import { ref, onMounted } from 'vue';

export function useTelegramEmbed(content) {
  const telegramWidgetContainer = ref(null);

  const loadTelegramWidget = () => {
    if (content && typeof content === 'string') {
      const widgetScript = document.createElement('script');
      widgetScript.src = 'https://telegram.org/js/telegram-widget.js';
      const extractedPart = content.split('https://t.me/')[1];
      widgetScript.dataset.telegramPost = extractedPart;
      widgetScript.dataset.width = '100%';
      if (
        !telegramWidgetContainer.value.querySelector(
          'script[src="https://telegram.org/js/telegram-widget.js"]'
        )
      ) {
        telegramWidgetContainer.value.append(widgetScript);
      }
    }
  };

  onMounted(loadTelegramWidget);

  return {
    telegramWidgetContainer,
  };
}
