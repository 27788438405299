<script setup>
import { ref } from 'vue';
import { Collapse } from 'vue-collapsed';
import { useEventTracking } from '#imports';

defineProps({
  title: {
    type: String,
    required: true,
  },
  content: {
    type: Array,
    required: true,
  },
});

const isOpen = ref(false);

const toggleContent = () => {
  isOpen.value = !isOpen.value;
  useEventTracking(
    'click',
    isOpen.value ? 'Collapsable Block Opened' : 'Collapsable Block Closed'
  );
};
</script>

<template>
  <div class="content-collapsible">
    <div class="content-collapsible__container">
      <button
        :class="isOpen && 'content-collapsible__title--opened'"
        class="content-collapsible__title"
        @click="toggleContent"
      >
        <span class="content-collapsible__icon"></span>
        <h2>{{ title }}</h2>
      </button>
      <Collapse :when="isOpen" class="content-collapsible__wrapper">
        <div class="content-collapsible__inner">
          <PostContent :content="content" />
        </div>
      </Collapse>
    </div>
  </div>
</template>

<style lang="postcss">
.content-collapsible {
  max-width: var(--content-max-width);
  padding: var(--article-content-padding-medium-vp);
  font-family: var(--body-font-family);
  margin: 24px auto 24px auto;
  z-index: 1;
  position: relative;

  @media (--medium-vp) {
    padding: var(--article-content-padding-medium-vp);
  }

  @media (--tablet-vp) {
    padding: var(--tablet-layout-padding);
  }

  @media (--small-vp) {
    padding: 0 12px;
  }

  &__container {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0%) 0%,
      rgba(255, 133, 234, 20%) 100%
    );
    padding: 10px 40px;
    border-radius: 0 0 12px 12px;

    @media (--tablet-vp) {
      padding: 10px;
    }
  }

  &__icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34' height='34' viewBox='0 0 34 34' fill='none'%3E%3Cg clip-path='url(%23clip0_221_2065)'%3E%3Cpath d='M16.6563 22.0872C16.9678 22.0878 17.2697 21.9793 17.5096 21.7805L25.5096 15.1139C25.7819 14.8875 25.9531 14.5623 25.9856 14.2098C26.0181 13.8572 25.9092 13.5062 25.6829 13.2339C25.4566 12.9616 25.1314 12.7903 24.7788 12.7578C24.4263 12.7253 24.0752 12.8342 23.8029 13.0605L16.6563 19.0339L9.50959 13.2739C9.3732 13.1631 9.21628 13.0804 9.04782 13.0305C8.87937 12.9806 8.70272 12.9645 8.52801 12.983C8.35331 13.0016 8.184 13.0546 8.02982 13.1388C7.87563 13.223 7.73962 13.3369 7.62959 13.4739C7.50748 13.611 7.415 13.7718 7.35795 13.9463C7.30089 14.1208 7.28049 14.3052 7.29801 14.4879C7.31553 14.6707 7.37061 14.8479 7.45979 15.0083C7.54896 15.1688 7.67032 15.3091 7.81626 15.4205L15.8163 21.8605C16.063 22.0279 16.3588 22.1077 16.6563 22.0872Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_221_2065'%3E%3Crect width='33' height='33' fill='white' transform='matrix(0 1 1 0 0.65625 0.753906)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    width: 33px;
    height: 33px;
    display: block;
    transition: transform 0.3s ease-in-out;
    flex-shrink: 0;
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    gap: 8px;
    cursor: pointer;
    border: none;
    border-radius: 16px;
    width: 100%;

    @media (--tablet-vp) {
      h2 {
        font-size: 16px;
      }
    }

    &--opened {
      border-radius: 16px 16px 0 0;
      background: transparent;

      & .content-collapsible__icon {
        transform: rotate(180deg);
      }
    }
  }

  &__inner {
    & .post-content {
      margin-top: 16px;
      padding: 0 !important;
      font-family: var(--body-font-family);
    }

    & .post-content p {
      padding: 0 !important;
    }

    .post-content > ol > li,
    .post-content > ul > li {
      padding-left: 35px;
      font-family: var(--body-font-family);
    }
  }
}
</style>
