<script setup>
import { computed, useDevice, useImageSource } from '#imports';
// eslint-disable-next-line import/no-extraneous-dependencies
import VueEasyLightbox from 'vue-easy-lightbox';
import { ref } from 'vue';

const props = defineProps({
  caption: {
    type: String,
    default: '',
  },
  source: {
    type: String,
    default: '',
  },
  asset: {
    type: Object,
    required: true,
  },
  size: {
    type: String,
    default: '',
  },
  isFullscreen: {
    type: Boolean,
    default: false,
  },
  isOriginalWidth: {
    type: Boolean,
    default: false,
  },
});

const assetId = computed(() => props.asset._ref);
const altText = computed(() => props.caption || `Изображение-${assetId.value}`);
const isLightboxOpen = ref(false);
const { isDesktop } = useDevice();
const verticalImage = props.size === 'isVerticalImage' && !props.isFullscreen;

function openLightbox() {
  if (props.size === 'isColumnWidth' || props.size === 'isVerticalImage') {
    isLightboxOpen.value = true;
  }
}

function closeLightbox() {
  isLightboxOpen.value = false;
}
// eslint-disable-next-line sonarjs/cognitive-complexity
const getImageSource = ({ dpr, isMobile = false }) => {
  const { isFullscreen, isOriginalWidth, size, asset } = props;
  const isOriginalWidthImage = isOriginalWidth && !isFullscreen;

  let width;
  if (isMobile) {
    width = isOriginalWidthImage ? undefined : 500;
  } else {
    if (!isFullscreen && !isOriginalWidth) width = 1320;
    if (isFullscreen) width = 1900;
    if (verticalImage) width = 890;
    if (isOriginalWidthImage) width = undefined;
  }

  const ratio = size === 'isVerticalImage' ? 3 / 4 : 1.78;
  const height = width !== undefined ? Math.round(width / ratio) : undefined;

  return useImageSource({
    image: asset,
    width,
    height,
    dpr,
  });
};
</script>

<template>
  <figure
    class="content-image"
    :class="{
      'content-image--fullscreen': props.isFullscreen,
      'content-image--original-width': props.isOriginalWidth,
      [`content-image--${props.size}`]: props.size,
    }"
  >
    <picture>
      <source
        media="(min-width: 768px)"
        :srcset="
          props.isOriginalWidth
            ? getImageSource({ dpr: 1 })
            : `${getImageSource({ dpr: 1 })},
              ${getImageSource({ dpr: 2 })} 2x`
        "
      />
      <NuxtImg
        class="content-image__img"
        :alt="altText"
        loading="lazy"
        :src="getImageSource({ dpr: 1, isMobile: true })"
        :srcset="`
          ${getImageSource({ dpr: 2, isMobile: true })} 2x,`"
        @click="openLightbox"
      />
    </picture>
    <figcaption
      v-if="caption || source"
      class="content-image__text"
      :class="{
        'content-image__text--fullscreen': props.isFullscreen,
        'content-image__text--original-width': props.isOriginalWidth,
      }"
    >
      <div v-if="caption" class="content-image__text__caption">
        {{ caption }}
      </div>
      <div v-if="source" class="content-image__text__source">
        {{ source }}
      </div>
    </figcaption>
    <VueEasyLightbox
      v-if="isLightboxOpen && isDesktop"
      :visible="isLightboxOpen"
      :zoom-disabled="true"
      :move-disabled="true"
      :scroll-disabled="true"
      :rotate-disabled="true"
      :imgs="[getImageSource({ dpr: 1 })]"
      @hide="closeLightbox"
    />
  </figure>
</template>

<style lang="postcss" scoped>
.content-image {
  margin: 40px auto;

  &--original-width {
    max-width: 856px;
  }

  &__text {
    max-width: var(--content-max-width);
    margin: 0 auto;

    &__caption {
      font-size: var(--h6-font-size);
      font-family: var(--body-font-family);
      line-height: var(--h6-line-height);
    }

    &__source {
      font-size: 14px;
      line-height: 22px;
      color: rgba(var(--black-color--rgb), 40%);
    }

    @media (max-width: 1330px) {
      padding: 0 10px;
    }

    @media (max-width: 1080px) {
      padding: var(--tablet-layout-padding);
    }

    @media (--small-vp) {
      margin: 0;
      padding: 0 10px;

      &__source {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &__img {
    width: 100%;
    max-width: var(--content-max-width);
    display: block;
    height: auto;
    margin: 0 auto 10px;
    border-radius: 16px;

    @media (--tablet-vp) {
      border-radius: 0;
    }

    @media (--small-vp) {
      margin: 0 auto 8px;
    }
  }

  &--isFullScreen {
    max-width: none;

    .content-image__img {
      max-width: none;
      border-radius: 0;
    }

    .content-image__text {
      max-width: var(--content-max-width);
      margin-right: auto;
      margin-left: auto;
      padding: var(--article-content-padding-medium-vp);
    }

    @media (max-width: 1080px) {
      .content-image__text {
        padding: var(--tablet-layout-padding);
      }
    }

    @media (--small-vp) {
      .content-image__text {
        padding: 0 12px;
      }
    }
  }

  &--isColumnWidth {
    max-width: var(--content-max-width);
    margin-right: auto;
    margin-left: auto;
    padding: var(--article-content-padding-medium-vp);

    .content-image__img {
      cursor: zoom-in;
    }

    @media (max-width: 1080px) {
      padding: 0 12px;

      .content-image__img {
        border-radius: 16px;
      }
    }
  }

  &--isBigImage {
    width: auto;
    max-width: var(--content-max-width);
    height: 100%;
    border-radius: 16px;
    padding: 0 20px;

    .content-image__img {
      max-height: 723px;
      height: 100%;
    }

    .content-image__text {
      max-width: var(--content-max-width);
      margin-left: -20px;
      margin-right: auto;
      padding: var(--article-content-padding-medium-vp);
    }

    @media (max-width: 1080px) {
      padding: 0 12px;
      height: auto;

      .content-image__img {
        border-radius: 16px;
      }

      .content-image__text {
        margin-left: -12px;
        padding: var(--tablet-layout-padding);
      }
    }

    @media (--small-vp) {
      .content-image__text {
        margin: 0;
        padding: 0;
      }
    }
  }

  &--isVerticalImage {
    max-width: var(--content-max-width);
    margin-right: auto;
    margin-left: auto;
    padding: var(--article-content-padding-medium-vp);

    .content-image__img {
      cursor: zoom-in;
      border-radius: 16px;
      max-width: fit-content;
      display: block;
      height: auto;
    }

    @media (max-width: 1080px) {
      padding: 0 12px;

      .content-image__img {
        width: 100%;
      }
    }

    @media (--small-vp) {
      .content-image__img {
        max-width: none;
      }
    }
  }

  @media (--small-vp) {
    margin: 20px auto;

    &--isBigimage {
      padding: 0 12px;
    }

    &--isFullScreen {
      .content-image__img {
        border-radius: 0;
      }
    }

    &--original-width {
      padding: 0;

      .content-image__img {
        width: 100%;
      }
    }
  }
}

.content-image::v-deep(.vel-modal) {
  background: rgba(0, 0, 0, 80%);
}

.content-image::v-deep(.vel-img) {
  border-radius: 16px;
}

.content-image::v-deep(.vel-toolbar) {
  display: none;
}

.content-image::v-deep(.vel-btns-wrapper .btn__close) {
  font-size: 52px;
  right: 10px;
  top: 50px;
}
</style>
