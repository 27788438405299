<script setup>
import { ref } from '#imports';
import { useEventTracking } from '~/utils/use-event-tracking';

defineProps({
  text: {
    type: String,
    required: true,
  },
});

const showForm = ref(false);

const handleToggleDonationForm = () => {
  showForm.value = !showForm.value;
  useEventTracking('Payment Form', 'Opened in article');
};
</script>

<template>
  <div class="content-donation">
    <img
      :class="{ 'content-donation__background-image--full': showForm }"
      alt="background image"
      class="content-donation__background-image"
      src="~/assets/images/donation-background.svg"
    />
    <img
      alt="donation title"
      class="content-donation__title"
      src="~/assets/images/donation-title.svg"
    />
    <div class="content-donation__text">{{ text }}</div>
    <div v-if="!showForm" class="content-donation__footer">
      <button
        class="content-donation__button"
        @click="handleToggleDonationForm"
      >
        Поддержать
      </button>
      <div class="content-donation__footer-text">
        Оформите разовое или ежемесячное пожертвование.
      </div>
    </div>
    <Transition mode="out-in" name="scale-in">
      <div v-if="showForm" class="content-donation__form">
        <DonationBlockPayment />
      </div>
    </Transition>
  </div>
</template>

<style lang="postcss" scoped>
.content-donation {
  position: relative;
  z-index: 1;
  max-width: var(--content-max-width);
  margin: 32px auto;
  padding: 80px 300px 50px 200px;
  color: var(--white-color);

  &__background-image {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-65%) rotate(-4deg) scale(130%);
    width: auto;
    height: 100%;
    object-fit: cover;
    transition: 0.15s width, 0.15s transform;
    user-select: none;
    pointer-events: none;

    &--full {
      width: auto;
      transform: translateX(-50%) rotate(-4deg) scale(110%);
    }
  }

  &__title {
    display: block;
    width: 655px;
    height: auto;
    box-sizing: content-box;

    @media (--tablet-vp) {
      width: 550px;
    }
  }

  &__text {
    max-width: 700px;
    margin-bottom: 16px;
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
  }

  &__footer {
    max-width: 784px;
    display: flex;
    gap: 16px;
  }

  &__button {
    position: relative;
    width: 226px;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 54px;
    padding: 5px 31px;
    border-radius: 12px;
    text-align: center;
    background-color: #74ffab;
    color: var(--black-color);
    cursor: pointer;
    transition: var(--default-transition) background-color;

    &:hover {
      background-color: #23ff7b;
    }

    &:active {
      background-color: #1ee46e;
    }
  }

  &__footer-text {
    max-width: 190px;
    font-size: 16px;
    line-height: 20px;
    color: var(--page-background-color);
    opacity: 0.8;
  }

  &__form {
    margin-top: 32px;
  }

  @media (max-width: 1439px) {
    &__form {
      position: relative;
      left: -10%;
    }
  }
  @media (--medium-vp) {
    padding: 100px 60px 50px 220px;
  }

  @media (--tablet-vp) {
    padding: 100px 60px 50px 60px;

    &__form {
      position: relative;
      left: 0;
    }

    &__background-image {
      transform: translateX(-55%) rotate(-3deg) scale(130%);
    }
  }

  @media (--small-vp) {
    padding: 50px 12px;

    &__title {
      width: 100%;
      min-width: auto;
    }

    &__text {
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 18px;
    }

    &__background-image {
      transform-origin: center;
      transform: translateX(-50%) rotate(-5deg) scale(120%);

      &--full {
        transform: translateX(-50%) rotate(-5deg) scale(120%);
      }
    }

    &__form {
      left: 0;
    }

    &__footer {
      flex-direction: column;
      gap: 8px;
    }

    &__button {
      width: auto;
      margin-bottom: 0;
    }

    &__footer-text {
      max-width: none;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
    }
  }

  @media (--x-small-vp) {
    padding: 40px 12px 60px 12px;
  }
}
</style>
