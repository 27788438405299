<script setup>
import { computed, useRoute } from '#imports';
import { processTextContent } from '~/utils/process-text-content';
import PostContentImage from '~/components/post-content/post-content-image.vue';
import PostContentCarousel from '~/components/post-content/post-content-carousel.vue';
import PostContentSpeaker from '~/components/post-content/post-content-speaker.vue';
import PostContentDisclaimer from '~/components/post-content/post-content-disclaimer.vue';
import PostContentAddition from '~/components/post-content/post-content-addition.vue';
import PostContentRecommendedPost from '~/components/post-content/post-content-recommended-post.vue';
import PostContentDonation from '~/components/post-content/post-content-donation.vue';
import PostContentAuthorOpinion from '~/components/post-content/post-content-author-opinion.vue';
import PostContentListBlock from '~/components/post-content/post-content-list-block.vue';
import PostContentNote from '~/components/post-content/post-content-note.vue';
import PostContentHighlight from '~/components/post-content/post-content-highlight.vue';
import PostContentRedHighlight from '~/components/post-content/post-content-red-highlight.vue';
import PostContentHighlightBlock from '~/components/post-content/post-content-highlight-block.vue';
import BaseExternalLink from '~/components/base-external-link.vue';
import PostContentAsideText from '~/components/post-content/post-content-aside-text.vue';
import PostContentEmbedHtml from '~/components/post-content-embed/post-content-embed-html.vue';
import PostContentEmbedFlourish from '~/components/post-content-embed/post-content-embed-flourish.vue';
import PostContentEmbedYoutube from '~/components/post-content-embed/post-content-embed-youtube.vue';
import PostContentEmbedTwitter from '~/components/post-content-embed/post-content-embed-twitter.vue';
import PostContentEmbedTiktok from '~/components/post-content-embed/post-content-embed-tiktok.vue';
import PostContentEmbedTelegram from '~/components/post-content-embed/post-content-embed-telegram.vue';
import PostContentEmbedInstagram from '~/components/post-content-embed/post-content-embed-instagram.vue';
import PostContentHr from '~/components/post-content/post-content-hr.vue';
import PostContentCarouselWithCaptions from '~/components/post-content/post-content-carousel-with-captions.vue';
import PostContentCollapsible from '~/components/post-content/post-content-collapsible.vue';

const props = defineProps({
  content: {
    type: Array,
    required: true,
  },
  noLeadArticle: {
    type: Boolean,
    default: false,
  },
  mobileGap: {
    type: Boolean,
    default: false,
  },
  isArticle: {
    type: Boolean,
    default: false,
  },
  isLead: {
    type: Boolean,
    default: false,
  },
  isDisclaimer: {
    type: Boolean,
    default: false,
  },
  isAddition: {
    type: Boolean,
    default: false,
  },
  isCollapsible: {
    type: Boolean,
    default: false,
  },
  isHiring: {
    type: Boolean,
    default: false,
  },
  isSectionQuote: {
    type: Boolean,
    default: false,
  },
  isNote: {
    type: Boolean,
    default: false,
  },
  isAuthorOpinion: {
    type: Boolean,
    default: false,
  },
  isStaticPage: {
    type: Boolean,
    default: false,
  },
});

const serializers = {
  types: {
    image: PostContentImage,
    gallery: PostContentCarousel,
    galleryWithCaption: PostContentCarouselWithCaptions,
    speaker: PostContentSpeaker,
    disclaimer: PostContentDisclaimer,
    collapsibleBlock: PostContentCollapsible,
    addition: PostContentAddition,
    recommendedArticle: PostContentRecommendedPost,
    recommendedNewsItem: PostContentRecommendedPost,
    donation: PostContentDonation,
    authorOpinion: PostContentAuthorOpinion,
    listBlock: PostContentListBlock,
    html: PostContentEmbedHtml,
    youtube: PostContentEmbedYoutube,
    twitter: PostContentEmbedTwitter,
    tiktok: PostContentEmbedTiktok,
    flourish: PostContentEmbedFlourish,
    telegram: PostContentEmbedTelegram,
    instagram: PostContentEmbedInstagram,
  },
  styles: {
    blockquote: 'blockquote',
    hr: PostContentHr,
  },
  marks: {
    note: PostContentNote,
    paintHighlight: PostContentRedHighlight,
    highlight: PostContentHighlight,
    highlightBlock: PostContentHighlightBlock,
    link: BaseExternalLink,
    asideText: PostContentAsideText,
  },
};

const processedContent = computed(() => processTextContent(props.content));
const route = useRoute();
const { previewId } = route.query;
</script>

<template>
  <div
    :class="{
      'post-content--lead': isLead,
      'post-content--article': isArticle,
      'post-content--disclaimer': isDisclaimer,
      'post-content--collapsible': isCollapsible,
      'post-content--addition': isAddition,
      'post-content--hiring': isHiring,
      'post-content--section-quote': isSectionQuote,
      'post-content--note': isNote,
      'post-content--author-opinion': isAuthorOpinion,
      'post-content--mobile-gap': mobileGap,
      'post-content--no-lead': noLeadArticle,
      'post-content--static-page': isStaticPage,
    }"
    class="post-content"
  >
    <NuxtErrorBoundary>
      <SanityContent :blocks="processedContent" :serializers="serializers" />
      <template v-if="previewId" #error="{ error }">
        <ErrorBoundary :error="error" />
      </template>
    </NuxtErrorBoundary>
  </div>
</template>

<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type-->
<style lang="postcss">
.post-content {
  position: relative;

  > p {
    font-size: var(--body-font-size);
    font-family: var(--body-font-family);
    line-height: var(--body-line-height);
    font-weight: 400;
    position: relative;
    margin: 16px 0;
  }

  > ol {
    counter-reset: section;

    > li {
      position: relative;
      margin-bottom: 32px;
      padding-left: 40px;

      &::before {
        font-weight: 700;
        position: absolute;
        left: 0;
        content: counters(section, '.') '. ';
        counter-increment: section;
        color: var(--accent-color);
      }
    }
  }

  > ul,
  > ol {
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
    margin: 16px 0;
    font-weight: 400;

    & > li {
      position: relative;
      margin-bottom: 12px;
      padding-left: 30px;
      font-family: var(--body-font-family);
    }

    & > li:last-child {
      margin-bottom: 0;
    }

    &::before {
      color: var(--accent-color);
    }
  }

  > ul > li::before {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 8px;
    height: 8px;
    content: '';
    border-radius: 2px 2px 2px 0;
    background-color: var(--accent-color);
  }

  > h1,
  > h2,
  > h3,
  > h4 {
    font-family: var(--title-font-family);
  }

  > h1 {
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 32px;
  }

  > h2 {
    font-size: var(--h3-font-size);
    font-weight: 700;
    line-height: 45px;
    position: relative;
    margin-bottom: 20px;
  }

  > h3 {
    font-size: 24px;
    line-height: 35px;
    position: relative;
    margin-bottom: 20px;
  }

  > h4 {
    font-size: var(--h4-font-size);
    line-height: 32px;
    margin-bottom: 20px;
  }

  > blockquote {
    font-size: var(--h3-font-size);
    font-family: var(--body-font-family);
    font-weight: 700;
    line-height: 40px;
    position: relative;
    z-index: 1;
    margin: 60px 0;

    &::after,
    &::before {
      position: absolute;
      z-index: -1;
      width: 90px;
      height: 90px;
      content: '';
      background-image: url('~/assets/images/quote.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }

    &::before {
      top: 0;
      right: auto;
      bottom: auto;
      left: -30px;
    }

    &::after {
      top: auto;
      right: 0;
      bottom: 0;
      left: auto;
      transform: rotate(180deg);
    }
  }

  &--article {
    & > p,
    & > h2,
    & > h3,
    & > h4,
    & > blockquote,
    & > ul,
    & > ol {
      max-width: var(--content-max-width);
      margin-right: auto;
      margin-left: auto;
      padding: var(--article-content-padding-medium-vp);

      @media (--medium-vp) {
        padding: var(--article-content-padding-medium-vp);
      }

      @media (--tablet-vp) {
        padding: var(--tablet-layout-padding);
      }
    }

    & > h2 {
      margin-top: 80px;

      &::before {
        position: absolute;
        z-index: 0;
        top: -30px;
        left: 50%;
        width: 99vw;
        height: 368px;
        content: '';
        transform: translateX(-50%);
        opacity: 0.25;
        background: linear-gradient(
          180deg,
          rgba(108, 33, 121, 80%) 32.6%,
          rgba(108, 33, 121, 0%) 87.77%
        );
        filter: blur(20px);
      }
    }

    & > blockquote {
      margin: 60px auto;
      padding-right: 454px;

      &::before {
        left: calc(228px - 90px);
      }

      &::after {
        right: calc(454px - 90px);
      }
    }

    @media (--tablet-vp) {
      & > blockquote {
        padding: var(--tablet-layout-padding);

        &::before {
          left: 12px;
        }

        &::after {
          right: 12px;
        }
      }
    }

    & > .list-block {
      padding: var(--article-content-padding-medium-vp);

      @media (--tablet-vp) {
        padding: var(--tablet-layout-padding);
      }
    }

    & > .content-disclaimer {
      padding: var(--article-content-padding-medium-vp);

      @media (--tablet-vp) {
        padding: var(--tablet-layout-padding);
      }
    }

    & > .embed {
      padding: var(--article-content-padding);

      @media (--tablet-vp) {
        padding: var(--tablet-layout-padding);
      }
    }

    & > .embed .youtube {
      margin-right: -56px;
      margin-left: 0;
      padding-bottom: 56%; /* force widget resolution 16:9 */
    }

    & > .recommended-post {
      padding: 0 316px 0 224px;

      @media (--medium-vp) {
        padding: 0 100px 0 220px;
      }

      @media (--tablet-vp) {
        padding: var(--tablet-layout-padding);
      }
    }

    & > .hr {
      max-width: 1440px;
      padding: var(--article-content-padding);
      @media (--tablet-vp) {
        padding: var(--tablet-layout-padding);
      }
    }
  }

  &--hiring {
    > ul,
    > ol,
    p {
      font-size: 22px;
      line-height: 26px;
    }

    > ul > li::before {
      top: 14px;
      left: 4px;
    }
  }

  &--lead {
    > ul,
    > ol,
    > p {
      font-size: 22px;
      line-height: 34px;
      font-weight: 500;
    }
  }

  &--section-quote {
    > ul,
    > ol,
    > p {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &--disclaimer {
    color: var(--title-light-color);
  }

  &--disclaimer,
  &--addition {
    blockquote {
      padding: 0 120px;
    }

    blockquote::before {
      left: 0;
    }

    @media (--tablet-vp) {
      blockquote::before {
        left: 0 !important;
      }

      blockquote::after {
        right: 0 !important;
      }
    }

    @media (--small-vp) {
      blockquote {
        padding: 0 50px;
      }
    }
  }

  &--author-opinion {
    > p,
    > blockquote,
    > ul,
    > ol {
      font-size: var(--h5-font-size);
      font-weight: 700;
      line-height: var(--h5-line-height);
    }
  }

  &--static-page {
    & > h2,
    & > h3,
    & > h4 {
      font-family: var(--main-font-family);
    }

    & :is(p) {
      font-size: 18px;
      line-height: 26px;
    }

    & :is(ul > li) {
      margin-bottom: 0;
      padding-left: 0;
    }

    & :is(ul > li)::before {
      content: none;
    }
  }

  &--note {
    > p:first-child,
    > h2:first-child,
    > h3:first-child,
    > h4:first-child,
    > blockquote:first-child,
    > ul:first-child,
    > ol:first-child {
      margin-top: 0;
    }

    > p:last-child,
    > h2:last-child,
    > h3:last-child,
    > h4:last-child,
    > blockquote:last-child,
    > ul:last-child,
    > ol:last-child {
      margin-bottom: 0;
    }
  }

  @media (--small-vp) {
    p {
      font-size: 16px;
      line-height: 22px;
      margin: 8px 0;
    }

    > ul,
    > ol {
      font-size: 16px;
      line-height: 22px;
      margin: 24px 0;

      & > li {
        margin-bottom: 16px;
        padding-left: 16px;
        word-break: break-word;
      }
    }

    > ul > li::before {
      top: 8px;
      left: 0;
      width: 8px;
      height: 8px;
    }

    > h1 {
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    > h2 {
      font-size: 20px;
      line-height: 29px;
      position: relative;
      margin-bottom: 12px;
    }

    > h3 {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 8px;
    }

    > h4 {
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 8px;
    }

    blockquote {
      font-size: 18px;
      line-height: 22px;
      margin: 30px 0;

      &::after,
      &::before {
        width: 40px;
        height: 40px;
        background-size: 40px 40px;
      }

      &::before {
        top: -10px;
        right: auto;
        bottom: auto;
        left: -10px;
      }

      &::after {
        top: auto;
        right: -10px;
        bottom: -10px;
        left: auto;
      }
    }

    &--hiring {
      > ul,
      > ol,
      p {
        font-size: 22px;
        line-height: 28px;
      }

      > ul > li::before {
        top: 14px;
        left: 4px;
      }

      > ul,
      > ol {
        & > li {
          margin-bottom: 24px;
          padding-left: 24px;
        }
      }
    }

    &--section-quote {
      > ul,
      > ol,
      > p {
        font-size: 18px;
        line-height: 22px;
      }
    }

    &--author-opinion {
      > p,
      > blockquote,
      > ul,
      > ol {
        font-size: 18px;
        line-height: 22px;
      }
    }

    &--article {
      & > p,
      & > h2,
      & > h3,
      & > h4,
      & > ul,
      & > ol {
        padding: 0 12px;
      }

      & > blockquote {
        margin: 30px auto;
        padding: 0 24px;

        &::before {
          left: 0;
        }

        &::after {
          right: 0;
        }
      }

      & > h2 {
        margin-top: 60px;
      }

      & > .list-block {
        padding: 0 4px;
      }

      & > .content-disclaimer {
        padding: 0 12px;
      }

      & > .embed {
        padding: 0 12px;
      }

      & > .embed .youtube {
        margin-right: auto;
        margin-left: auto;
        padding-bottom: 56.25%; /* force widget resolution 16:9 */
      }

      & > .recommended-post {
        padding: 0 12px;
      }

      & > .content-image--original-width {
        padding: 0;
      }

      & > .content-image > .content-image-text {
        padding: 0 12px;
      }

      & > .content-image > .content-image-text--original-width {
        padding: 0 12px;
      }

      & > .hr {
        padding: 0 12px;
      }

      &--disclaimer,
      &--addition {
        word-break: break-word;

        & > p {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    &--mobile-gap {
      & > p,
      & > h2,
      & > h3,
      & > h4,
      & > blockquote,
      & > ul,
      & > ol,
      & > figure {
        &:first-child:not(h2) {
          margin-top: 40px;
          padding-top: 24px;
        }
      }
    }
  }

  @media (--desktop-vp) {
    &--no-lead {
      padding-top: 40px;

      & > h2 {
        &:first-child {
          margin-top: 0;
          padding-top: 0;
        }
      }

      & > p {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
