<template>
  <mark class="paint-highlight">
    <slot />
  </mark>
</template>

<style lang="postcss" scoped>
.paint-highlight {
  padding: 0 12px;
  color: #fff;
  background-color: transparent;
  background-image: url('/assets/images/highlight.svg');
  background-position: center;
  box-decoration-break: clone;
  font-weight: normal;
}
</style>
