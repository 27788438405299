<script setup>
import { useEmbed } from '~/composables/use-embed';
import { onBeforeRouteUpdate } from '#imports';
import { network } from '~/constants/embedded-social-scripts';

defineProps({
  content: {
    type: String,
    required: true,
  },
  caption: {
    type: String,
    default: '',
  },
});

const { loadEmbed } = useEmbed({
  socialMediaName: 'flourish',
  script: network.flourish,
});

onBeforeRouteUpdate(() => {
  loadEmbed();
});
</script>

<template>
  <div class="embed embed--flourish" :title="caption">
    <div class="embed__container">
      <div class="embed__content" v-html="content" />
    </div>
    <div class="embed__caption">{{ caption }}</div>
  </div>
</template>

<style lang="postcss" scoped>
@import './styles.css';
</style>
