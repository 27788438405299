<script setup>
import { computed } from '#imports';

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  article: {
    type: Object,
    default: () => ({}),
  },
  newsItem: {
    type: Object,
    default: () => ({}),
  },
});

const isArticle = computed(() => Object.keys(props.article).length > 0);
const isNewsItem = computed(() => Object.keys(props.newsItem).length > 0);
</script>

<template>
  <div class="recommended-post">
    <div class="recommended-post__text">{{ text }}</div>
    <a
      v-if="isArticle && article.isArticleExternalSourceType"
      :href="article.externalSource"
      class="recommended-post__card"
      target="_blank"
    >
      <PostCard
        :is-full-image="true"
        :post="article"
        shadow-color="#101010"
        size="medium"
      />
    </a>
    <NuxtLink
      v-if="isArticle && !article.isArticleExternalSourceType"
      :to="{ name: 'articles-slug', params: { slug: article.slug.current } }"
      class="recommended-post__card"
    >
      <PostCardArticle
        :is-full-image="true"
        :post="article"
        shadow-color="#101010"
        size="medium"
        subtitle-color="#FFF4FA"
        title-color="#FFF7FB"
      />
    </NuxtLink>
    <NuxtLink
      v-else-if="isNewsItem"
      :to="{ name: 'news-slug', params: { slug: newsItem.slug.current } }"
      class="recommended-post__card recommended-post__card--news"
    >
      <div class="recommended-post__news-title">
        {{ newsItem.title }}
      </div>
      <PostCardDate
        :date="newsItem.publishedAt"
        class="recommended-post__news-date"
      />
    </NuxtLink>
  </div>
</template>

<style lang="postcss" scoped>
.recommended-post {
  display: flex;
  max-width: var(--content-max-width);
  margin: 40px auto;
  gap: 32px;

  &__text {
    font-size: 18px;
    line-height: 24px;
    position: relative;
    z-index: 1;
    max-width: 240px;

    @media (--medium-vp) {
      font-size: 18px;
      line-height: 24px;
    }

    &::after {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 188px;
      height: 188px;
      content: '';
      background: linear-gradient(
        210.79deg,
        #fdfb84 32.6%,
        rgba(253, 251, 132, 0%) 87.77%
      );
      filter: blur(40px);
    }
  }

  &__card {
    position: relative;
    z-index: 1;
    max-width: 550px;
    min-width: 550px;

    @media (--medium-vp) {
      max-width: 500px;
      min-width: 500px;
    }

    &--news {
      display: flex;
      flex-direction: column;
      max-width: 600px;
      height: 100%;
      min-height: 248px;
      padding: 16px;
      border: 1px solid rgba(17, 17, 17, 10%);
      border-radius: 16px;
      background-color: rgba(var(--white-color--rgb), 70%);
    }
  }

  &__news-date {
    margin-top: 16px;
  }

  &__news-title {
    font-family: var(--title-font-family);
    font-size: var(--h5-font-size);
    line-height: var(--h5-line-height);
    margin-bottom: auto;
    transition: var(--default-transition) color;
  }

  &__card--news:hover {
    .recommended-post__news-title {
      color: var(--accent-color);
    }
  }

  @media (--small-vp) {
    align-items: center;
    flex-direction: column;
    margin: 24px auto;
    gap: 8px;

    &__text {
      font-size: 16px;
      line-height: 20px;
      max-width: none;
      text-align: center;
      color: var(--accent-color);

      &::after {
        right: 0;
        left: 0;
        width: 100%;
        height: 135px;
      }
    }

    &__card {
      min-width: 350px;

      &--news {
        min-height: 134px;
        padding: 8px;
      }
    }

    &__news-title {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
</style>
