<script setup>
import { resolveComponent } from '#imports';

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
});

const component =
  props.post._type === 'news'
    ? resolveComponent('post-card-news')
    : resolveComponent('post-card-article');
</script>

<template>
  <Component :is="component" :post="post" />
</template>
