<script setup>
/* eslint-disable import/no-unresolved */
import { ref, useImageSource } from '#imports';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

const props = defineProps({
  caption: {
    type: String,
    default: '',
  },
  source: {
    type: String,
    default: '',
  },
  images: {
    type: Array,
    required: true,
  },
  size: {
    type: String,
    default: '',
  },
});

const swiper = ref();
const modules = [Navigation, Pagination];

const breakpoints = {
  768: {
    spaceBetween: 16,
    height: 651,
  },
};

function setSwiper(swiperInstance) {
  swiper.value = swiperInstance;
}

function getImageAssetId(image) {
  return image.asset._ref;
}

function getImageCaption(image) {
  return `Изображение-${getImageAssetId(image)}`;
}

const getImageSource = ({ image, isMobile, dpr }) => {
  const isOriginalWidth = props.size === 'isOriginalWidthImage';
  const isColumnWidth = props.size === 'isColumnWidth';
  const isBigGallery = props.size === 'isBigGallery';
  let width;
  if (isMobile) {
    width = isOriginalWidth ? undefined : 500;
  } else {
    if (!isOriginalWidth) width = 1320;
    if (isColumnWidth) width = 820;
    if (isBigGallery) width = 1320;
    if (isOriginalWidth) width = undefined;
  }

  const ratio = 1.78;
  const height = Math.round(width / ratio);

  return useImageSource({
    image,
    width,
    height,
    dpr,
  });
};
</script>

<template>
  <div
    :class="{
      'content-carousel': true,
      [`content-carousel--${size}`]: size,
    }"
  >
    <div class="content-carousel__slides-container">
      <Swiper
        :modules="modules"
        :space-between="8"
        :height="195"
        :slides-per-view="'auto'"
        :grab-cursor="true"
        :centered-slides="true"
        :slide-to-clicked-slide="true"
        :breakpoints="breakpoints"
        :pagination="{
          type: 'fraction',
        }"
        @swiper="setSwiper"
      >
        <SwiperSlide
          v-for="item in props.images"
          :key="item._id"
          :class="{
            'content-carousel__slide': true,
            [`content-carousel__slide--${size}`]: size,
          }"
        >
          <picture>
            <source
              media="(min-width: 768px)"
              :srcset="`
                ${getImageSource({
                  image: item.asset,
                  dpr: 1,
                })} 1x,
                ${getImageSource({
                  image: item.asset,
                  dpr: 2,
                })} 2x
              `"
            />
            <img
              class="content-carousel__img"
              :alt="getImageCaption(item)"
              loading="lazy"
              :src="`${getImageSource({ image: item.asset, dpr: 1 })}`"
              :srcset="`
                ${getImageSource({ image: item.asset, dpr: 2 })} 2x
              `"
            />
          </picture>
        </SwiperSlide>
        <div class="content-carousel__text">
          <div v-if="caption" class="content-carousel__caption">
            {{ props.caption }}
          </div>
          <div v-if="source" class="content-carousel__source">
            {{ props.source }}
          </div>
        </div>
      </Swiper>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.content-carousel {
  margin: 40px auto;

  &__slide {
    border-radius: 16px;
    max-width: 1100px;

    &--isBigGallery {
      max-width: 1320px;
    }

    &--isColumnWidth {
      max-width: 820px;
    }
  }

  &__img {
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 16px;
  }

  &__text {
    max-width: var(--content-max-width);
    margin-right: auto;
    margin-left: auto;
    padding: var(--article-content-padding-medium-vp);
  }

  &__caption {
    font-size: var(--h6-font-size);
    line-height: var(--h6-line-height);
  }

  &__source {
    font-size: 14px;
    line-height: 22px;
    color: rgba(var(--black-color--rgb), 40%);
  }

  @media (max-width: 1439px) {
    &__slide {
      width: calc(100vw - 50px);
    }
  }
  @media (--tablet-vp) {
    &__text {
      padding: var(--tablet-layout-padding);
    }

    &__slide {
      width: calc(100vw - 50px);

      &--isColumnWidth {
        .content-carousel__text {
          width: 85%;
          max-width: 1440px;
          margin: 0 auto;
          padding: 0;
        }
      }
    }
  }

  @media (--small-vp) {
    margin: 24px auto;

    &__text {
      margin: 8px auto 0;
      padding: 0 12px;
    }

    &--isColumnWidth {
      .content-carousel__text {
        width: auto;
        max-width: 1440px;
        margin: 8px auto 0;
        padding: 0 12px;
      }
    }

    &__slide {
      width: calc(100vw - 50px);
    }

    &__img {
      border-radius: 8px;
    }

    &__caption {
      font-size: 16px;
      line-height: 22px;
    }

    &__source {
      font-size: 12px;
      line-height: 16px;
    }
  }

  @media (--x-small-vp) {
    &__slide {
      width: calc(100vw - 50px);
    }
  }
}

.content-carousel::v-deep(.swiper-slide) {
  transition: var(--default-transition) transform,
    var(--default-transition) opacity;
}

.content-carousel::v-deep(.swiper-slide-active) {
  opacity: 1;
  transform: scale(1);
}

.content-carousel::v-deep(.swiper-slide.swiper-slide-next),
.content-carousel::v-deep(.swiper-slide.swiper-slide-prev) {
  opacity: 0.2;
  transform: scale(0.9);
  cursor: pointer;
}

.content-carousel::v-deep(.swiper-slide.swiper-slide-prev) {
  transform-origin: right center;
}

.content-carousel::v-deep(.swiper-slide.swiper-slide-next) {
  transform-origin: left center;
}

.content-carousel::v-deep(.swiper-pagination) {
  margin: 5px 0;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  color: rgba(var(--black-color--rgb), 40%);
}

.content-carousel::v-deep(.swiper-button-next),
.content-carousel::v-deep(.swiper-button-prev) {
  color: var(--accent-color);
}
</style>
