<template>
  <span class="aside-text">
    <span class="aside-text__wrapper">
      <span class="aside-text__background">
        <slot></slot>
      </span>
      <span class="aside-text__text">
        <slot></slot>
      </span>
    </span>
  </span>
</template>

<style scoped lang="postcss">
.aside-text {
  display: block;
  position: absolute;
  top: 0;
  right: 20px;
  width: 230px;
  font-size: var(--h5-font-size);
  line-height: 30px;
  font-weight: 700;

  &__wrapper {
    display: block;
    position: relative;
  }

  &__background {
    background-color: var(--accent-second-color);
    box-shadow: 4px 0 0 var(--accent-second-color),
      -4px 0 0 var(--accent-second-color);
    color: transparent;
    box-decoration-break: clone;
  }

  &__text {
    position: absolute;
    top: -13px;
    left: 0;
    width: inherit;
  }

  @media (--medium-vp) {
    right: 10px;
    font-size: var(--h5-font-size);
    line-height: 28px;
    width: 250px;
  }

  @media (--tablet-vp) {
    width: 100%;
    position: static;
    display: block;
    margin: 8px;
    padding: 4px;
    font-size: 20px;
    line-height: 28px;
    background: linear-gradient(
      95.35deg,
      #fbff35 0%,
      rgba(251, 255, 53, 0%) 100.24%
    );

    &__background {
      background-color: transparent;
      box-shadow: none;
      color: var(--black-color);
    }

    &__text {
      display: none;
    }
  }

  @media (--small-vp) {
    font-size: 16px;
    line-height: 20px;
  }
}
</style>
