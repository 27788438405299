<script>
export default {
  name: 'PostContentEmbedYoutube',
  props: {
    content: {
      type: String,
      required: true,
    },
    caption: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div class="embed embed--youtube" :title="caption">
    <div class="embed__content" v-html="content" />
    <div v-if="caption" class="embed__caption">
      {{ caption }}
    </div>
  </div>
</template>

<style lang="postcss" scoped>
@import './styles.css';
</style>
