<script setup>
import { computed } from '#imports';
import { formatNewsDate } from '~/utils/format-news-date';

const props = defineProps({
  date: {
    type: String,
    required: true,
  },
  fontColor: {
    type: String,
    default: '',
  },
});

const formattedDate = computed(() => formatNewsDate(props.date));
const classes = computed(() => ({ 'post-card-date--light': props.isLight }));
const fontColorStyles = computed(() => {
  if (!props.fontColor) return '';
  return {
    color: props.fontColor,
    borderBottom: `1px solid ${props.fontColor}`,
  };
});
</script>

<template>
  <div class="post-card-date" :class="classes" :style="fontColorStyles">
    {{ formattedDate }}
  </div>
</template>

<style lang="postcss" scoped>
.post-card-date {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding-bottom: 3px;
  border-bottom: 1px solid var(--text-dark-color);
  color: var(--text-dark-color);
  opacity: 0.4;

  @media (--small-vp) {
    font-size: 11px;
    line-height: 13px;
  }
}
</style>
