<template>
  <mark class="highlight">
    <slot />
  </mark>
</template>

<style lang="postcss" scoped>
.highlight {
  background-color: var(--light-violet-color);
  box-decoration-break: clone;
  box-shadow: 4px 0 0 var(--light-violet-color),
    -4px 0 0 var(--light-violet-color);
}
</style>
