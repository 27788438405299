<script>
import { network } from '~/constants/embedded-social-scripts';
import { appendEmbeddedSocialScript } from '~/utils/append-embedded-social-script';

export default {
  name: 'PostContentEmbedTwitter',
  props: {
    content: {
      type: String,
      required: true,
    },
    caption: {
      type: String,
      default: '',
    },
  },
  mounted() {
    appendEmbeddedSocialScript(network.twitter);
  },
};
</script>

<template>
  <div class="embed embed--twitter" :title="caption">
    <div class="embed__content" v-html="content" />
    <div class="embed__caption">{{ caption }}</div>
  </div>
</template>

<style lang="postcss" scoped>
@import './styles.css';
</style>
