<script setup>
import { computed, useImageSource } from '#imports';

const props = defineProps({
  article: {
    type: Object,
    required: true,
  },
});

const caption = computed(
  () => props.article.image?.caption || `Изображение-${props.article.title}`
);

function getImageSource({ dpr, isMobile = false }) {
  let height;
  if (isMobile) {
    height = props.article.size === 'small' ? 320 : 400;
  } else {
    height = props.article.size === 'big' ? 740 : 360;
  }
  return useImageSource({
    image: props.article.image,
    height,
    dpr,
  });
}
</script>

<template>
  <picture>
    <source
      media="(min-width: 768px)"
      :srcset="`
            ${getImageSource({ dpr: 1 })} 1x,
            ${getImageSource({ dpr: 2 })} 2x
           `"
    />
    <NuxtImg
      class="post-card__image"
      :alt="caption"
      sizes="xl:100vw lg:100vw md:100vw sm:100vw xs:100vw"
      loading="lazy"
      :src="getImageSource({ isMobile: true })"
      :srcset="`${getImageSource({
        dpr: 2,
        isMobile: true,
      })} 2x`"
    />
  </picture>
</template>

<style lang="postcss" scoped>
@import './styles.css';
</style>
