<script setup>
import { computed, useArticleAuthors } from '#imports';
import { hexToRgb } from '~/helpers/hex-to-rgb';

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
  size: {
    type: String,
    validator: (value) => ['small', 'medium', 'big', ''].includes(value),
    default: '',
  },
  // deprecated
  titleColor: {
    type: String,
    default: '',
  },
  // deprecated
  subtitleColor: {
    type: String,
    default: '',
  },
  isFullImage: {
    type: [String, Boolean],
    default: '',
  },
  shadowColor: {
    type: String,
    default: '',
  },
  withBorder: {
    type: Boolean,
    default: false,
  },
});

const size = computed(() => (props.size === '' ? props.post.size : props.size));
const isFullImage = computed(() =>
  props.isFullImage === '' ? props.post.isFullImage : props.isFullImage
);
const getColor = (colorFromPicker, defaultColor) =>
  colorFromPicker?.hex || defaultColor;

const titleColor = computed(() =>
  getColor(props.post.titleColorFromColorPicker, props.post.titleColor)
);
const subtitleColor = computed(() =>
  getColor(props.post.subtitleColorFromColorPicker, props.post.subtitleColor)
);
const shadowColorCustom = computed(() =>
  getColor(props.post.shadowColorFromColorPicker, props.post.shadowColor)
);

const authors = useArticleAuthors(props.post?.crew);
const titleColorStyle = computed(() =>
  props.titleColor === ''
    ? { color: titleColor.value }
    : { color: props.titleColor }
);
const subtitleColorStyle = computed(() =>
  props.subtitleColor === ''
    ? { color: subtitleColor.value }
    : { color: props.subtitleColor }
);
const shadowColor = computed(() =>
  props.shadowColor === '' ? shadowColorCustom.value : props.shadowColor
);
const shadowStyle = computed(() => {
  if (isFullImage.value) {
    return { '--shadow-color': hexToRgb(shadowColor.value) };
  }
  return '';
});
const avatarSize = computed(() =>
  size.value === 'small' ? 'small' : 'medium'
);
const isCustomBackground = computed(() => Boolean(props.post.backgroundColor));
const background = computed(() =>
  isCustomBackground.value
    ? {
        backgroundColor:
          props.post.backgroundColor.hex || props.post.backgroundColor,
        opacity: 1,
      }
    : ''
);
const classes = computed(() => ({
  'post-card--medium': size.value === 'medium',
  'post-card--big': size.value === 'big',
  'post-card--full-image': isFullImage.value,
  'post-card--custom-background': isCustomBackground.value,
  'post-card--with-border': props.withBorder,
}));
</script>

<template>
  <div class="post-card" :class="classes" :style="background">
    <div v-if="isFullImage" class="post-card__shadow" :style="shadowStyle">
      <div class="post-card__shadow-hover"></div>
    </div>
    <p class="post-card__title" :style="titleColorStyle">{{ post.title }}</p>
    <p
      v-if="isFullImage"
      class="post-card__subtitle"
      :style="subtitleColorStyle"
    >
      {{ post.subtitle }}
    </p>
    <div class="post-card__image-block">
      <PostCardArticleImage :article="post" />
    </div>
    <p
      v-if="!isFullImage"
      class="post-card__subtitle"
      :style="subtitleColorStyle"
    >
      {{ post.subtitle }}
    </p>
    <div v-if="authors" class="post-card__authors">
      <PostCardAuthors
        :authors="authors"
        :font-color="titleColor || post.titleColor"
        :avatar-size="avatarSize"
      />
    </div>

    <div
      v-if="isFullImage"
      class="post-card__shadow post-card__shadow--bottom"
      :style="shadowStyle"
    >
      <div
        class="post-card__shadow-hover post-card__shadow-hover--bottom"
      ></div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
@import './styles.css';
</style>
