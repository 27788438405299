<script setup>
import { ref } from '#imports';

const props = defineProps({
  content: {
    type: Array,
    required: true,
  },
});

const isOpenModal = ref(false);
function extractTextFromSanityContent(blocks) {
  let extractedText = '';

  blocks.forEach((block) => {
    if (block._type === 'block') {
      block.children.forEach((child) => {
        if (child._type === 'span') {
          extractedText += child.text;
        }
      });
    }
  });

  return extractedText;
}
const text = extractTextFromSanityContent(props.content);
</script>

<template>
  <span class="note">
    <span
      class="note__mobile-link note__reference"
      @click="isOpenModal = !isOpenModal"
    >
      <slot />
    </span>
    <span class="note__tooltip">
      <BaseTooltip>
        <template #reference>
          <span class="note__reference">
            <slot />
          </span>
        </template>
        <template #content>
          <div class="note__content">
            <PostContent :content="props.content" is-note />
          </div>
        </template>
      </BaseTooltip>
      <span class="note__hidden">{{ text }}</span>
    </span>
    <Teleport to="body">
      <div v-if="isOpenModal" class="modal">
        <BaseMobileModal @close="isOpenModal = false">
          <div class="note__content">
            <PostContent :content="props.content" />
          </div>
        </BaseMobileModal>
      </div>
    </Teleport>
  </span>
</template>

<style scoped lang="postcss">
.note {
  display: inline-block;
  position: relative;

  &__hidden {
    display: none;
  }

  &__reference {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 0 4px 0 8px;
    color: var(--accent-color);
    background-color: rgba(var(--accent-color--rgb), 0.1);
    border-radius: 4px;
    line-height: 1.2;
    cursor: pointer;

    &::after {
      content: '❔';
      background-color: rgba(var(--accent-color--rgb), 0.7);
      border-radius: 50%;
      width: 16px;
      height: 16px;
      font-size: 11px;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  &__icon {
    height: 0.75em;
    width: 0.75em;
    margin-left: 4px;
  }

  &__content {
    width: 400px;
    max-width: 400px;
    overflow: hidden;
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }

  &__mobile-link {
    display: none;
  }

  @media (--small-vp) {
    &__tooltip {
      display: none;
    }

    &__reference {
      &::after {
        width: 12px;
        height: 12px;
        font-size: 8px;
      }
    }

    &__mobile-link {
      display: flex;
    }

    &__content {
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>
