<script setup>
import { useTelegramEmbed } from '~/composables/use-telegram-embed';

const props = defineProps({
  content: {
    type: String,
    required: true,
  },
  caption: {
    type: String,
    default: '',
  },
});

const { telegramWidgetContainer } = useTelegramEmbed(props.content);
</script>

<template>
  <div class="embed embed--telegram" :title="caption">
    <div class="embed__container">
      <div ref="telegramWidgetContainer" class="embed__content" />
    </div>
    <div class="embed__caption">{{ caption }}</div>
  </div>
</template>

<style lang="postcss" scoped>
@import './styles.css';
</style>
